export const defChallengeStats = {
    "id": "dsChallengeStats",
    "viewName": "aviw_Fundraising_ChallengesStatistics",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "PublicStatistics",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "RepetitionLabel",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "SignedUpParticipants",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "TotalSponsored",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Paid",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "TotalRepetitions",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "ActiveParticipants",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "TotalSponsors",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "PercentPaid",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "AverageRepetitions",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "AverageAmountOfSponsors",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "AverageSponsored",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        },
        {
            "name": "Balance",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
}

export const defGroupsTop5Reps = {
    "id": "dsGroupsTop5Reps",
    "viewName": "aviw_Fundraising_ChallengesGroups",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Challenge_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Closed",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": "dsChallengeStats",
    "masterDetailDefinition": [
        {
            "detailField": "Challenge_ID",
            "operator": "equals",
            "masterField": "ID"
        }
    ],
    "clientSideHandler": false,
    "maxRecords": 5,
    "dynamicLoading": false,
    "whereClause": "Closed IS NULL",
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
}

export const defGroupsTop5Sponsors = {
    "id": "dsGroupsTop5Sponsor",
    "viewName": "aviw_Fundraising_ChallengesGroups",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Challenge_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Closed",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "TotalSponsored",
            "sortOrder": 1,
            "sortDirection": "desc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": "dsChallengeStats",
    "masterDetailDefinition": [
        {
            "detailField": "Challenge_ID",
            "operator": "equals",
            "masterField": "ID"
        }
    ],
    "clientSideHandler": false,
    "maxRecords": 5,
    "dynamicLoading": false,
    "whereClause": "Closed IS NULL",
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
}


export const defTopListFunded = {
    "id": "dsToplistFunded",
    "viewName": "aviw_Fundraising_PublicStatistics",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "SponsoredAmount",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Challenge_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Closed",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": "dsChallengeStats",
    "masterDetailDefinition": [
        {
            "detailField": "Challenge_ID",
            "operator": "equals",
            "masterField": "ID"
        }
    ],
    "clientSideHandler": false,
    "maxRecords": 0,
    "dynamicLoading": false,
    "whereClause": "Closed IS NULL",
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
}

export const defToplistRepititions = {
    "id": "dsToplistRepititions",
    "viewName": "aviw_Fundraising_PublicStatistics",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Repetitions",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Challenge_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Closed",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "RepetitionLabel",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": "dsChallengeStats",
    "masterDetailDefinition": [
        {
            "detailField": "Challenge_ID",
            "operator": "equals",
            "masterField": "ID"
        }
    ],
    "clientSideHandler": false,
    "maxRecords": 0,
    "dynamicLoading": false,
    "whereClause": "Closed IS NULL",
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
}